<div class="header">
  <div>
    <img [src]="logo" />
    <span class="application">{{ line.application }}</span> -
    <span class="segment">{{ line.market }} - {{ line.segment }}</span>
  </div>
  <div>
    <img *ngIf="line.digital" class="mr-3" src="assets/img/digital.png"/>
    <img class="p-2" src="assets/img/air-liquide-creative-oxygen.svg"/>
  </div>
</div>
<div class="content">
  <div class="main">
    <section>
      <h3 translate>CUSTOMER_DESCRIPTION</h3>
      <ul>
        <li><strong translate>NAME</strong>{{line.customerName}}</li>
        <li><strong translate>COUNTRY</strong>{{line.country}}</li>
        <li><strong translate>PRODUCT</strong>{{line.customerProduct}}</li>
        <li><strong translate>PROCESS</strong>
          <ul>
            <li><strong translate>INSTALLATION</strong>{{line.installation}}</li>
            <li><strong translate>OPERATION</strong>{{line.operation}}</li>
          </ul>
        </li>
        <li><strong translate>CHALLENGE</strong>
          <ul>
            <li *ngFor="let challenge of line.challenges">{{challenge}}</li>
          </ul>
        </li>
      </ul>
    </section>

    <section>
      <h3 translate>CUSTOMER_BENEFITS</h3>
      <ul>
        <li *ngFor="let benefit of line.benefits; index as idx;">
          <strong>{{ 'BENEFIT' | translate }} {{ idx + 1 }}</strong>{{ benefit }}
        </li>
      </ul>
    </section>

    <section>
      <h3 translate>AL_SOLUTION</h3>
      <ul>
        <li><strong translate>DATE</strong>{{line.date}}</li>
        <li><strong translate>APPLICATION</strong>{{line.application}}</li>
        <li><strong>{{line.gasesBrand}} - {{line.supplyChain}}</strong></li>
        <li><strong translate>MONTHLY_VOLUME</strong>{{line.monthlyVolumes}}</li>
        <li><strong translate>EQUIPMENT</strong>{{line.equipment}} - <strong>{{line.oem}}</strong></li>
        <li><strong translate>EXPERTISE</strong>{{line.expertise}}</li>
        <li><strong translate>ADVANTAGES</strong>
          <ul>
            <li *ngFor="let advantage of line.advantages">{{advantage}}</li>
          </ul>
        </li>
      </ul>
    </section>

    <section>
      <h3 translate>AL_RESULT</h3>
      <ul>
        <li><strong translate>GAS_SALES</strong>{{line.gasSales}}</li>
        <li><strong translate>EQUIPMENT_SALES</strong>{{line.equipmentSales}}</li>
        <li><strong translate>SERVICE_SALES</strong>{{line.serviceSales}}</li>
      </ul>
      <div class="al-result-detail">
        <div *ngIf="line.additionalInfo">
          <strong translate>ADDITIONAL_INFO</strong><br/>
          {{line.additionalInfo}}
        </div>
        <div *ngIf="line.proposedSolution">
          <strong translate>COMPETITION_PROPOSAL</strong><br/>
          {{line.proposedSolution}}
        </div>
        <div *ngIf="line.link">
          <a [href]="line.link" translate>LINK</a>
        </div>
      </div>
    </section>
  </div>
  <div class="side">
    <img [src]="img1"/>
    <img [src]="img2"/>
    <div class="contact">
      <span class="contact-arrow">></span>
      <h3 translate>CONTACT</h3>
      <section *ngFor="let contact of line.contacts">
        <strong>{{contact.name}}</strong><span *ngIf="contact.role"> - {{contact.role}}</span>
      </section>
    </div>
    <!-- NOTE Generate slide -->
    <button class="btn btn-primary" [disabled]="(storeService.isLoading$ | async)
      || !(storeService.connectivity$ | async)
      || !(storeService.isAuthenticated$ | async)
      " (click)="generateSlide()">
      <ng-container *ngIf="!(storeService.isLoading$ | async)">
        {{ 'OPEN_IN_SLIDE' | translate | uppercase }}
      </ng-container>
      <ng-container *ngIf="storeService.isLoading$ | async">
        <span class="spinner-grow spinner-grow-sm align-middle" role="status" aria-hidden="true"></span>
        <span class="ms-1 align-middle">{{ 'LOADING' | translate }}</span>
      </ng-container>
    </button>
  </div>
</div>
