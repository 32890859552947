<!-- NOTE Navbar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-primary py-1 sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/home']">
      <img src="./assets/img/air-liquide-cube-white.svg" alt="Air Liquide logo" class="img-fluid me-2"/>
    </a>
    <div class="align-middle mx-auto text-white h5 m-0 fw-normal">{{ title | uppercase }}</div>
    <button class="navbar-toggler d-block" type="button" (click)="onClickOffcanvas(content)" [disabled]="!(storeService.canLeavePage$ | async)">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
</nav>

<!-- NOTE Loading -->
<app-progress-bar *ngIf="storeService.isLoading$ | async" class="position-fixed w-100"></app-progress-bar>

<!-- NOTE Offcanvas -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-body">
    <app-side-menu></app-side-menu>
  </div>
</ng-template>

<!-- NOTE Content -->
<ng-content></ng-content>
