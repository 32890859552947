// External modules
import { ArrayTyper } from '@caliatys/array-typer';

// Models
import { Contact }    from '@models/contact.model';

export class Line
{
  public domain           !: string;
  public application      !: string;
  public market           !: string;
  public segment          !: string;
  public customerName     !: string;
  public customerProduct  !: string;
  public contacts         !: Contact[];
  public cluster          !: string;
  public country          !: string;
  public city             !: string;
  public date             !: string;
  public installation     !: string;
  public operation        !: string;
  public challenges       !: string[];
  public gases            !: string[];
  public gasesBrand       !: string;
  public supplyChain      !: string;
  public equipment        !: string;
  public digital          !: boolean;
  public oem              !: string;
  public expertise        !: string;
  public advantages       !: string;
  public benefits         !: string;
  public monthlyVolumes   !: string;
  public gasSales         !: string;
  public equipmentSales   !: string;
  public serviceSales     !: string;
  public additionalInfo   !: string;
  public proposedSolution !: string;
  public picture1         !: string;
  public picture2         !: string;
  public link             !: string;

  public reference        !: string; // NOTE Used in view

  constructor(json ?: any)
  {
    if (!json)
      return;

    this.domain           = json.Domain;
    this.application      = json.Application;
    this.market           = json.Market;
    this.segment          = json.Segment;
    this.customerName     = json.CustomerName;
    this.customerProduct  = json.CustomerProduct;
    this.contacts         = ArrayTyper.asArray(Contact, json.Contacts);
    this.cluster          = json.Cluster;
    this.country          = json.Country;
    this.city             = json.City;
    this.date             = json.Date;
    this.installation     = json.Installation;
    this.operation        = json.Operation;
    this.challenges       = json.Challenges;
    this.gases            = json.Gases;
    this.supplyChain      = json.SupplyChain;
    this.equipment        = json.Equipment;
    this.digital          = json.Digital;
    this.oem              = json.Oem;
    this.expertise        = json.Expertise;
    this.advantages       = json.Advantages;
    this.benefits         = json.Benefits;
    this.monthlyVolumes   = json.MonthlyVolumes;
    this.gasSales         = json.GasSales;
    this.equipmentSales   = json.EquipmentSales;
    this.serviceSales     = json.ServiceSales;
    this.additionalInfo   = json.AdditionalInfo;
    this.proposedSolution = json.ProposedSolution;
    this.picture1         = json.Picture1;
    this.picture2         = json.Picture2;
    this.link             = json.Link;

    this.reference        = `${this.customerName} | ${this.country} | ${this.city}`;
  }
}
