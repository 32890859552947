// Angular modules
import { Component }    from '@angular/core';
import { OnInit }       from '@angular/core';

// Services
import { StoreService } from '@services/store.service';

@Component({
  selector    : 'app-not-found',
  templateUrl : './not-found.component.html',
  styleUrls   : ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit
{

  constructor(public storeService : StoreService) { }

  public ngOnInit() : void
  {
  }

}
