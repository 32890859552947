export class Lang
{
  public Code !: string;
  public Name !: string;

  constructor(json ?: any)
  {
    if (!json)
      return;

    this.Code = json.Code;
    this.Name = json.Name;
  }
}
