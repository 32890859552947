// Angular modules
import { Component }     from '@angular/core';
import { OnDestroy }     from '@angular/core';
import { OnInit }        from '@angular/core';

// Helpers
import { StorageHelper } from '@helpers/storage.helper';

// Enums
import { UserRight }     from '@enums/user-right.enum';

// Services
import { AuthService }   from '@services/auth.service';
import { StoreService }  from '@services/store.service';

@Component({
  selector    : 'app-side-menu',
  templateUrl : './side-menu.component.html',
  styleUrls   : ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy
{
  public isAdmin : boolean = false;

  constructor
  (
    public  storeService : StoreService,
    private authService  : AuthService,
  )
  {
  }

  public async ngOnInit() : Promise<void>
  {
    const userRight = StorageHelper.getUserRight();
    if (Number(userRight) === UserRight.Admin)
      this.isAdmin = true;
  }

  public ngOnDestroy() : void
  {
  }

  // -------------------------------------------------------------------------------------------
  // ---- NOTE Actions -------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------

  public async onClickSignOut() : Promise<void>
  {
    await this.authService.signOut();
  }

  // -------------------------------------------------------------------------------------------
  // ---- NOTE Subscriptions -------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------

}
