// Angular modules
import { Injectable }          from '@angular/core';
import { Router }              from '@angular/router';

// AWS modules
import { FederatedResponse }   from '@aws-amplify/auth/src/types';
import { FederatedUser }       from '@aws-amplify/auth/src/types';

// External modules
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { SocialAuthService }   from '@abacritt/angularx-social-login';
import { SocialUser }          from '@abacritt/angularx-social-login';
import { TranslateService }    from '@ngx-translate/core';
import { CognitoUser }         from 'amazon-cognito-identity-js';
import { CognitoUserSession }  from 'amazon-cognito-identity-js';
import { Auth }                from 'aws-amplify';

// Internal modules
import { ToastManager }        from '@blocks/toast/toast.manager';

// Interfaces
import { GoogleJwtPayload }    from '@interfaces/google.interface';
import { GoogleUser }          from '@interfaces/google.interface';

// Helpers
import { StorageHelper }       from '@helpers/storage.helper';
import { StringHelper }        from '@helpers/string.helper';

// Enums
import { UserRight }           from '@enums/user-right.enum';

// Models
import { User }                from '@models/user.model';

// Services
import { StoreService }        from '@services/store.service';
import { SyncService }         from '@services/sync.service';

@Injectable()
export class AuthService
{
  constructor
  (
    private translateService : TranslateService,
    private syncService      : SyncService,
    private storeService     : StoreService,
    private socialService    : SocialAuthService,
    private toastManager     : ToastManager,
    private router           : Router,
  )
  {

  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Helpers -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // private async refreshGoogleToken() : Promise<boolean>
  // {
  //   try
  //   {
  //     await this.socialService.initState.toPromise();
  //     await this.socialService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  //     return true;
  //   }
  //   catch (e)
  //   {
  //     console.log('AuthService : refreshGoogleToken -> failed', e);
  //     return false;
  //   }
  // }

  private async refreshCognitoToken(user : CognitoUser, session : CognitoUserSession) : Promise<boolean>
  {
    return new Promise(async (resolve) =>
    {
      try
      {
        user.refreshSession(session.getRefreshToken(), (err, res) =>
        {
          if (err)
            console.log('AuthService : refreshCognitoToken -> failed (err)', err);
          return resolve(err ? false : true);
        });
      }
      catch (e)
      {
        console.log('AuthService : refreshCognitoToken -> failed (e)', e);
        return resolve(false);
      }
    });
  }

  public async isAuthenticated() : Promise<boolean>
  {
    try
    {
      // NOTE Get authenticated user
      const user : CognitoUser | GoogleUser = await Auth.currentAuthenticatedUser();

      // console.log(`SyncService : isAuthenticated -> ${ user ? '✔️' : '❌' }`, user);

      // NOTE Cognito
      if (user instanceof CognitoUser)
      {
        // NOTE Check token expired
        const session   = await Auth.currentSession();
        const exp       = session.getIdToken().getExpiration();
        const isExpired = StringHelper.tokenIsExpired(exp);

        if (isExpired && this.storeService.getConnectivity())
        {
          const state = await this.refreshCognitoToken(user, session);
          this.storeService.setIsAuthenticated(state);
          return state;
        }
      }
      else
      {
        // NOTE Check token expired
        const googleToken = StringHelper.getParsedJwt<GoogleJwtPayload>(user.token);
        if (!googleToken)
          return false;
        const isExpired = StringHelper.tokenIsExpired(googleToken.exp);

        if (isExpired && this.storeService.getConnectivity())
        {
          // FIXME How can I refresh Google token ?
          // const state = await this.refreshGoogleToken();
          const state = false;
          this.storeService.setIsAuthenticated(state);
          return state;
        }
      }

      this.storeService.setIsAuthenticated(true);
      return true;
    }
    catch (e)
    {
      this.storeService.setIsAuthenticated(false);
      return false;
    }
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Google --------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async googleSignIn(socialUser : SocialUser, exp : number) : Promise<boolean>
  {
    this.storeService.setIsLoading(true);

    const googleResponse : FederatedResponse = {
      token      : socialUser.idToken,
      expires_at : exp,
    };
    const federatedUser : FederatedUser = {
      email : socialUser.email,
      name  : socialUser.name
    };

    // NOTE AWS auth
    const response = await this.awsSignIn(googleResponse, federatedUser);
    if (!response)
    {
      this.storeService.setIsLoading(false);
      return false;
    }

    // NOTE Lambda check
    const userRight = await this.checkUserEmail(socialUser.email);
    if (userRight === null)
    {
      this.storeService.setIsLoading(false);
      return false;
    }

    // NOTE Store user with right
    const user = new User(socialUser.email, socialUser.name, 'google');
    StorageHelper.setUser(user);
    StorageHelper.setUserRight(userRight);

    this.storeService.setIsAuthenticated(true);
    this.storeService.setIsLoading(false);

    return true;
  }

  private awsSignIn(googleResponse : FederatedResponse, federatedUser : FederatedUser) : Promise<boolean>
  {
    return new Promise((resolve) =>
    {
      Auth.federatedSignIn('google', googleResponse, federatedUser).then(value =>
      {
        console.log('awsSignIn', value)
        return resolve(true);
      })
      .catch(err =>
      {
        console.error('LoginGoogleComponent : awsSignIn -> federatedSignIn', err);
        this.toastManager.quickShow(err.toString());
        return resolve(false);
      });
    });
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Sign out ------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async signOut(sessionExpired : boolean = false) : Promise<void>
  {
    this.storeService.setIsLoading(true);
    return new Promise(async (resolve) =>
    {
      // NOTE Force google sign out
      try { await this.socialService.signOut(true); } catch (e) { };
      // NOTE AWS sign out
      try { await Auth.signOut(); } catch (e) { };

      this.storeService.setIsAuthenticated(false);
      this.storeService.setIsLoading(false);
      StorageHelper.removeUserRight();
      StorageHelper.removeUser();

      if (sessionExpired)
        this.toastManager.quickShow('Session expired', 'warning', true);

      this.router.navigate(['/auth']);
      return resolve();
    });
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Cognito -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async cognitoSignIn(email : string, password : string) : Promise<void>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      Auth.signIn({ username : email, password }).then(async (cognitoUser : CognitoUser) =>
      {
        this.storeService.setIsLoading(false);
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED')
        {
          this.router.navigate(['/auth/validate-account'], { state : { email, password }});
          return resolve();
        }

        const userRight = await this.checkUserEmail(email);
        if (userRight === null)
          return resolve();

        // NOTE Store user with right
        const userEmail = await this.getCognitoEmail(cognitoUser);
        const user      = new User(userEmail, userEmail, 'cognito');
        StorageHelper.setUser(user);
        StorageHelper.setUserRight(userRight);

        this.storeService.setIsAuthenticated(true);
        this.storeService.setIsLoading(false);
        this.router.navigate(['/home']);
        return resolve();
      })
      .catch(async (err : Error) =>
      {
        this.storeService.setIsLoading(false);
        if (err.name === 'UserNotConfirmedException')
        {
          this.toastManager.quickShow(err.message, 'warning');
          await this.resendConfirmationCode(email);
          this.router.navigate(['/auth/validate-account'], { state : { email, password }});
          return resolve();
        }

        this.toastManager.quickShow(err.message);
        return resolve();
      });
    });
  }

  private getCognitoEmail(user : CognitoUser) : Promise<string>
  {
    return new Promise((resolve) =>
    {
      user.getUserAttributes((err, data) =>
      {
        if (!data)
          return resolve('');
        const item = data.find(i => i.Name === 'email');
        if (!item)
          return resolve('');
        return resolve(item.Value);
      });
    });
  }

  public async resetPassword(email : string) : Promise<void>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      Auth.forgotPassword(email).then(_ =>
      {
        this.storeService.setIsLoading(false);
        // NOTE Redirect to validate new password
        this.router.navigate(['/auth/validate-new-password'], { state : { email }});
        return resolve();
      })
      .catch((err : Error) =>
      {
        this.storeService.setIsLoading(false);

        let errMsg = 'ERROR_RESET_PASSWORD';
        switch (err.name)
        {
          case 'UserNotFoundException' :
            errMsg = 'ERROR_USER_NOT_FOUND';
            break;
          case 'UserNotConfirmedException' :
            errMsg = 'ERROR_USER_NOT_CONFIRMED';
            break;
        }
        const message = this.translateService.instant(errMsg);
        this.toastManager.quickShow(message);
        return resolve();
      });
    });
  }

  public async signUp(email : string, password : string) : Promise<boolean>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      Auth.signUp({username : email, password : password, attributes : {}, validationData : []}).then(_ =>
      {
        const message = this.translateService.instant('SUCCESS_ACCOUNT_CREATION');
        this.toastManager.quickShow(message, 'success');
        this.storeService.setIsLoading(false);
        return resolve(true);
      })
      .catch((err : Error) =>
      {
        this.toastManager.quickShow(err.message ?? 'AuthService : signUp');
        this.storeService.setIsLoading(false);
        return resolve(false);
      });
    });
  }

  public async resendConfirmationCode(email : string) : Promise<void>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      Auth.resendSignUp(email).then(_ =>
      {
        this.storeService.setIsLoading(false);
        const message = this.translateService.instant('SUCCESS_RESEND_CODE');
        this.toastManager.quickShow(message, 'success');
        return resolve();
      })
      .catch(_ =>
      {
        this.storeService.setIsLoading(false);
        const message = this.translateService.instant('ERROR_RESEND_CODE');
        this.toastManager.quickShow(message);
        return resolve();
      });
    });
  }

  public async validateAccount(email : string, password : string, code : number) : Promise<void>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      Auth.confirmSignUp(email, String(code)).then(_ =>
      {
        this.storeService.setIsLoading(false);
        // NOTE Redirect to login and authenticate
        this.router.navigate(['/auth/login'], { state : { email, password }});
        return resolve();
      })
      .catch((err) =>
      {
        this.storeService.setIsLoading(false);
        this.toastManager.quickShow(err && err.message ? err.message : 'ValidateAccountComponent : validateAccount -> confirmSignUp');
        return resolve();
      });
    });
  }

  public async confirmReset(code : number, email : string, password : string) : Promise<void>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      Auth.forgotPasswordSubmit(email, String(code), password).then(_ =>
      {
        this.storeService.setIsLoading(false);
        // NOTE Redirect to login and authenticate
        this.router.navigate(['/auth/login'], { state : { email, password }});
        return resolve();
      })
      .catch((err : Error) =>
      {
        this.storeService.setIsLoading(false);
        let errMsg = 'ERROR_RESET_PASSWORD';
        switch (err.name)
        {
          case 'CodeMismatchException' :
            errMsg = 'ERROR_CODE_INVALID';
            break;
          case 'ExpiredCodeException' :
            errMsg = 'ERROR_CODE_EXPIRED';
            break;
        }
        const message = this.translateService.instant(errMsg);
        this.toastManager.quickShow(message);
        return resolve();
      });
    });
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Lambda --------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async checkUserEmail(email : string) : Promise<UserRight | null>
  {
    this.storeService.setIsLoading(true);
    return new Promise((resolve) =>
    {
      this.syncService.validateUserEmail(email).then(async (res) =>
      {
        this.storeService.setIsLoading(false);
        const response = await res.data;
        // if (response === 'false' || response === '0')
        // {
        //   const message = this.translateService.instant('EMAIL_NOT_AUTHORIZED');
        //   this.toastManager.quickShow(message);
        //   return resolve(null);
        // }

        return resolve(Number(response));
      })
      .catch(err =>
      {
        const message = err ? this.translateService.instant(err.toString()) : 'AuthService : checkUserEmail';
        this.toastManager.quickShow(message);
        this.storeService.setIsLoading(false);
        return resolve(null);
      });
    });
  }

}
