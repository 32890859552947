<li class="mb-3 {{ row.isActive ? '' : 'opacity-50' }}">

  <a [routerLink]="[ row.path ]" class="row align-items-center p-3 text-white" title="{{ row.title }}">
    <span class="col-10">{{ row.title }}{{ row.children !== null ? ' - ' + row.children : '' }}</span>
    <span class="col-2 text-end"><svg-icon class="align-middle" [src]="'assets/img/icons/mat-chevron-right.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px' }"></svg-icon></span>
  </a>

  <span class="overlay"></span>
  <img [src]="image" alt="{{ row.title }}"/>

</li>
