// Angular modules
import { Injectable }       from '@angular/core';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject }  from 'rxjs';

// Internal modules
import { environment }      from '@env/environment';

// Helpers
import { StorageHelper }    from '@helpers/storage.helper';

/**
 * Angular Application State Management: You Do (Not) Need External Data Stores like NgRx, Redux, and Other
 * https://www.maestralsolutions.com/angular-application-state-management-you-do-not-need-external-data-stores/
 */
@Injectable()
export class StoreService
{
  // NOTE Data
  private readonly _pageTitleSource        = new BehaviorSubject<string>(environment.appName);
  private readonly _updateAvailableSource  = new BehaviorSubject<boolean>(false);
  private readonly _connectivitySource     = new BehaviorSubject<boolean>(false);
  private readonly _canLeavePageSource     = new BehaviorSubject<boolean>(false);
  private readonly _isLoadingSource        = new BehaviorSubject<boolean>(false);
  private readonly _isAuthenticatedSource  = new BehaviorSubject<boolean>(false);

  // NOTE Events
  readonly pageTitle$       = this._pageTitleSource.asObservable();
  readonly updateAvailable$ = this._updateAvailableSource.asObservable();
  readonly connectivity$    = this._connectivitySource.asObservable();
  readonly canLeavePage$    = this._canLeavePageSource.asObservable();
  readonly isLoading$       = this._isLoadingSource.asObservable();
  readonly isAuthenticated$ = this._isAuthenticatedSource.asObservable();

  constructor
  (
    private translateService : TranslateService
  )
  {
    this.initConnectivity();
    this.initCanLeavePage();
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Can leave page ------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getCanLeavePage() : boolean
  {
    return this._canLeavePageSource.getValue();
  }

  public setCanLeavePage(state : boolean) : void
  {
    this._canLeavePageSource.next(state);
  }

  private async initCanLeavePage() : Promise<void>
  {
    const activeVersion = await StorageHelper.getActiveVersion();
    const canLeavePage  = activeVersion ? true : false;
    this.setCanLeavePage(canLeavePage);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Page title ----------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getPageTitle() : string
  {
    return this._pageTitleSource.getValue();
  }

  public setPageTitle(title : string, translate : boolean = true) : void
  {
    const pageTitle = translate ? this.translateService.instant(title) : title;
    this._pageTitleSource.next(pageTitle);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Is authenticated ----------------------------------------------------
  // -------------------------------------------------------------------------------

  public getIsAuthenticated() : boolean
  {
    return this._isAuthenticatedSource.getValue();
  }

  public setIsAuthenticated(state : boolean) : void
  {
    this._isAuthenticatedSource.next(state);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Is loading ----------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getIsLoading() : boolean
  {
    return this._isLoadingSource.getValue();
  }

  public setIsLoading(state : boolean) : void
  {
    this._isLoadingSource.next(state);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Update available ----------------------------------------------------
  // -------------------------------------------------------------------------------

  public getUpdateAvailable() : boolean
  {
    return this._updateAvailableSource.getValue();
  }

  public setUpdateAvailable(state : boolean) : void
  {
    this._updateAvailableSource.next(state);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Connectivity --------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getConnectivity() : boolean
  {
    return this._connectivitySource.getValue();
  }

  public setConnectivity(state : boolean) : void
  {
    this._connectivitySource.next(state);
  }

  private async initConnectivity() : Promise<void>
  {
    const networkStatus = window.navigator.onLine;
    this.setConnectivity(networkStatus);

    window.addEventListener('online', (e) =>
    {
      this.setConnectivity(true);
    });
    window.addEventListener('offline', (e) =>
    {
      this.setConnectivity(false);
    });
  }
}
