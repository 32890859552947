// Angular modules
import { Injectable }    from '@angular/core';
import { CanLoad }       from '@angular/router';
import { Router }        from '@angular/router';

// Helpers
import { StorageHelper } from '@helpers/storage.helper';

@Injectable({
  providedIn : 'root'
})
export class SyncGuard implements CanLoad
{
  constructor(private router : Router) {}

  async canLoad() : Promise<boolean>
  {
    const activeVersion = await StorageHelper.getActiveVersion();

    if (!activeVersion)
    {
      this.router.navigate(['/synchronization']);
      return false;
    }

    return true;
  }
}
