// Angular modules
import { Component }        from '@angular/core';
import { Input }            from '@angular/core';
import { OnInit }           from '@angular/core';
import { DomSanitizer }     from '@angular/platform-browser';
import { SafeUrl }          from '@angular/platform-browser';

// External modules
import { TranslateService } from '@ngx-translate/core';

// Internal modules
import { ToastManager }     from '@blocks/toast/toast.manager';
import { environment }      from '@env/environment';

// Helpers
import { StorageHelper }    from '@helpers/storage.helper';

// Models
import { Line }             from '@models/line.model';

// Services
import { StoreService }     from '@services/store.service';
import { SyncService }      from '@services/sync.service';

@Component({
  selector    : 'app-block-slide',
  templateUrl : './block-slide.component.html',
  styleUrls   : ['./block-slide.component.scss']
})
export class BlockSlideComponent implements OnInit
{
  @Input() line !: Line;

  public img1 !: SafeUrl | string | null;
  public img2 !: SafeUrl | string | null;

  public logo !: SafeUrl | string | null;

  private defaultLogo : string = 'assets/img/air-liquide-altecref.svg';

  constructor
  (
    public  storeService     : StoreService,
    private syncService      : SyncService,
    private toastManager     : ToastManager,
    private translateService : TranslateService,
    private domSanitizer     : DomSanitizer,
  ) { }

  // -------------------------------------------------------------------------------
  // ---- NOTE Init ----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async ngOnInit() : Promise<void>
  {
    // NOTE Images
    this.img1 = await this.getImage(this.line.picture1);
    this.img2 = await this.getImage(this.line.picture2);

    // NOTE Logo
    const db = StorageHelper.getDatabase();
    if (!db)
      return;

    const imageKey = db.images.DomainLogo[this.line.domain] ? this.line.domain : null;
    if (!imageKey)
    {
      this.logo = this.defaultLogo;
      return;
    }

    const icon = await this.getImage(imageKey, true);
    this.logo  = icon ?? this.defaultLogo;
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Actions -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async generateSlide() : Promise<void>
  {
    this.storeService.setIsLoading(true);
    const res = await this.syncService.generateSlide(this.line as Line);

    // NOTE Open google slide
    if ((res as any).Payload)
    {
      const url = (res as any).Payload;
      this.storeService.setIsLoading(false);
      window.open(JSON.parse(url), '_blank');
      return;
    }

    this.storeService.setIsLoading(false);
    this.toastManager.quickShow(this.translateService.instant('ERROR_GENERATING_SLIDE'));
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Helpers -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  private async getImage(imgKey : string | null, nullable : boolean = false) : Promise<SafeUrl | string | null>
  {
    if (!imgKey)
      return nullable ? null : environment.placeholder;

    const data : Blob = await StorageHelper.getIDBItem(`images/${imgKey}`);

    if (!data)
      return nullable ? null : environment.placeholder;

    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Requests ------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Subscription --------------------------------------------------------
  // -------------------------------------------------------------------------------

}
