export class Contact
{
  public name !: string;
  public role !: string;

  constructor(json ?: any)
  {
    if (!json)
      return;

    this.name = json.Name;
    this.role = json.Role;
  }
}
