// Angular modules
import { Injectable }       from '@angular/core';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { Auth }             from 'aws-amplify';
import * as AWS             from 'aws-sdk';
import axios                from 'axios';
import { AxiosResponse }    from 'axios';

// Internal modules
import { ToastManager }     from '@blocks/toast/toast.manager';
import { environment }      from '@env/environment';

// Helpers
import { StorageHelper }    from '@helpers/storage.helper';

// Models
import { Database }         from '@models/database.model';
import { Line }             from '@models/line.model';

// Services
import { StoreService }     from '@services/store.service';

@Injectable()
export class SyncService
{
  public lambda !: AWS.Lambda;
  public s3     !: AWS.S3;

  public static DB_PREFIX = 'DATABASE::';

  private clientConfig !: AWS.Lambda.ClientConfiguration;

  constructor
  (
    private storeService     : StoreService,
    private translateService : TranslateService,
    private toastManager     : ToastManager,
  )
  {
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE S3 ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async updateActiveVersion(version : string) : Promise<boolean>
  {
    // To clear localforage and potentially dead data we have to :
    // - Download the last S3 version
    // - Check the integrity of the user preferences (active settings)
    //     If active preferences exist in the latest version of S3
    //       We can clear everything except them
    //       We can insert last S3 version

    if (!this.storeService.getConnectivity() || !this.storeService.getIsAuthenticated())
      return false;

    // NOTE Get database
    const db = await this.getS3Object(`${SyncService.DB_PREFIX}${version}`);
    if (db instanceof Error)
      return false;

    // NOTE Store database
    await this.storeJson(db);

    // NOTE Get images keys
    const imgKeys = await this.listAllKeys(`images/`);
    if (imgKeys === null)
      return false;

    // NOTE Get images
    for (const file of imgKeys)
    {
      const fileKey = file.Key || '';
      const img = await this.getS3Object(fileKey);
      if (img instanceof Error)
        return false;

      await this.storeImage(img, fileKey);
    }

    // NOTE Store version
    await StorageHelper.setActiveVersion(version);

    this.storeService.setUpdateAvailable(false);
    return true;
  }

  private async getS3Object(fileKey : string) : Promise<AWS.AWSError | AWS.S3.GetObjectOutput>
  {
    return new Promise((resolve) =>
    {
      this.s3.getObject({ Bucket : environment.S3_BUCKET, Key : fileKey }, (err, data) =>
      {
        if (err)
        {
          console.error('SyncService : getS3Object -> getObject', err.toString());
          return resolve(err);
        }
        return resolve(data);
      });
    });
  }

  private async listS3Objects(opts : AWS.S3.ListObjectsV2Request) : Promise<AWS.AWSError | AWS.S3.ListObjectsV2Output>
  {
    return new Promise((resolve) =>
    {
      this.s3.listObjectsV2(opts, (err, data) =>
      {
        if (err)
        {
          console.error('SyncService : listS3Objects -> listObjectsV2', err.toString());
          return resolve(err);
        }
        return resolve(data);
      });
    });
  }

  private async listAllKeys(folderName : string | undefined = undefined) : Promise<AWS.S3.ObjectList | null>
  {
    let ended  : boolean           = false;
    let result : AWS.S3.ObjectList = [];
    let opts   : AWS.S3.ListObjectsV2Request = {
      Bucket   : environment.S3_BUCKET,
      Prefix   : folderName,
    };
    while (ended === false)
    {
      const res = await this.listS3Objects(opts);
      if (res instanceof Error)
        return null;

      // NOTE Update result
      result = result.concat(res.Contents as any);

      if (!res.IsTruncated)
      {
        ended = true;
        continue;
      }

      // NOTE Update options
      opts.ContinuationToken = res.NextContinuationToken;
    }
    return result;
  }

  public async getLastVersion() : Promise<string | null>
  {
    if (!this.storeService.getConnectivity() || !this.storeService.getIsAuthenticated())
      return null;

    const creds = await Auth.currentCredentials();
    this.clientConfig = {
      region      : environment.region,
      credentials : Auth.essentialCredentials(creds),
    };
    this.s3 = new AWS.S3(this.clientConfig);

    let data = await this.listAllKeys(SyncService.DB_PREFIX);

    if (data === null || data.length === 0)
    {
      // NOTE Prevent error message, in case creating new version in progress
      // const message = this.translateService.instant('EMPTY_S3_BUCKET');
      // this.toastManager.quickShow(message);
      return null;
    }

    // NOTE Order files by LastModified
    const orderedList = data.sort((a : AWS.S3.Object, b : AWS.S3.Object) =>
    {
      if (!a.LastModified || !b.LastModified)
        return 0;
      return b.LastModified.getTime() - a.LastModified.getTime();
    });

    const lastFolder = orderedList[0];
    if (!lastFolder.Key)
      return null;
    const lastVersion = lastFolder.Key.replace(SyncService.DB_PREFIX, '');

    return lastVersion;
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Lambda --------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async sendEmail(title : string, content : string) : Promise<AWS.Lambda.InvocationResponse | AWS.AWSError>
  {
    // NOTE Get user
    const user = StorageHelper.getUser();

    if (!user)
      return Promise.reject();

    const params: AWS.Lambda.InvocationRequest = {
      FunctionName   : environment.sendMailLambda,
      InvocationType : 'RequestResponse',
      LogType        : 'None',
      Payload        : JSON.stringify({
        to : [{
          address     : user.email,
          displayName : user.name,
        }],
        subject : title,
        body    : content
      })
    };
    return this.invokeLambda(params);
  }

  public async generateSlide(line : Line) : Promise<AWS.Lambda.InvocationResponse | AWS.AWSError>
  {
    // NOTE Get user
    const user = StorageHelper.getUser();

    if (!user)
      return Promise.reject();

    const params: AWS.Lambda.InvocationRequest = {
      FunctionName  : environment.arnLambda + environment.slideLambda,
      InvocationType: 'RequestResponse',
      LogType       : 'None',
      Payload       : JSON.stringify({ line : line, email : user.email })
    };
    return this.invokeLambda(params);
  }

  public validateUserEmail(userEmail : string) : Promise<AxiosResponse>
  {
    return axios.post(environment.validateUserEmailEndpoint, { email: userEmail });
  }

  public newUserRequest(userEmail : string) : Promise<AxiosResponse>
  {
    return axios.post(environment.requestUserCreationEndpoint, { email: userEmail });
  }

  public validateNewUserRequest(email : string, validated : boolean) : Promise<AWS.Lambda.InvocationResponse | AWS.AWSError>
  {
    const params: AWS.Lambda.InvocationRequest = {
      FunctionName   : environment.arnLambda + environment.manageUserCreationLambda,
      InvocationType : 'RequestResponse',
      LogType        : 'None',
      Payload        : JSON.stringify({ email, validated }),
    };
    return this.invokeLambda(params);
  }

  /**
   * NOTE Generate database.json with images inside folder versionName from g-sheet
   * Update g-sheet file of translation (with keys)
   */
  public generateDatabase(versionName : string) : Promise<AWS.Lambda.InvocationResponse | AWS.AWSError>
  {
    const params : AWS.Lambda.InvocationRequest = {
      FunctionName   : environment.arnLambda + environment.syncLambda,
      InvocationType : 'RequestResponse',
      LogType        : 'None',
      Payload        : JSON.stringify({version: `${SyncService.DB_PREFIX}${versionName}`})
    };
    return this.invokeLambda(params);
  }

  // NOTE Lambda

  public invokeLambda(params : AWS.Lambda.InvocationRequest) : Promise<AWS.Lambda.InvocationResponse | AWS.AWSError>
  {
    return Auth.currentCredentials().then((cred) =>
    {
      this.clientConfig = {
        region      : environment.region,
        credentials : Auth.essentialCredentials(cred),
        maxRetries  : 10,
      };
      this.lambda = new AWS.Lambda(this.clientConfig);

      return new Promise((resolve, reject) =>
      {
        this.lambda.invoke(params, (err, data) =>
        {
          if (err)
          {
            console.error('SyncService : invokeLambda -> ' + params.FunctionName, err.toString());
            this.toastManager.quickShow(err.toString());
            return reject(err);
          }
          return resolve(data);
        });
      });
    });
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Helpers -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  private async storeImage(s3Object : AWS.S3.GetObjectOutput, fileKey : string) : Promise<void>
  {
    // NOTE Get content
    const item = new Blob([s3Object.Body as any], { type : s3Object.ContentType });
    // NOTE Store content
    await StorageHelper.setIDBItem(fileKey, item);
  }

  private async storeJson(s3Object : AWS.S3.GetObjectOutput) : Promise<void>
  {
    // NOTE Get content
    const json = new TextDecoder('utf-8').decode(s3Object.Body as any);
    const db   = new Database(json);
    // NOTE Store content
    StorageHelper.setDatabase(db);
  }
}
