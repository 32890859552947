<app-progress-bar *ngIf="dataIsLoading"></app-progress-bar>

<form *ngIf="!dataIsLoading" [formGroup]="formGroup" (ngSubmit)="onClickSubmit()" autocomplete="off">

  <!-- NOTE Language -->
  <div class="row align-items-center mb-3">
    <div class="col">
      {{ 'LANGUAGE' | translate }} :
    </div>
    <div class="col-5">
      <select class="form-select w-100" formControlName="language">
        <option [value]="language.Code" *ngFor="let language of languages">{{ language.Name | translate }}</option>
      </select>
    </div>
  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid || formGroup.pending || formIsLoading">
      <ng-container *ngIf="!formIsLoading">
        {{ 'SAVE' | translate }}
      </ng-container>
      <ng-container *ngIf="formIsLoading">
        <span class="spinner-grow spinner-grow-sm align-middle" role="status" aria-hidden="true"></span>
        <span class="ms-1 align-middle">{{ 'LOADING' | translate }}</span>
      </ng-container>
    </button>
  </div>
</form>
