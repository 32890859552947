// Angular modules
import { Component }     from '@angular/core';
import { Input }         from '@angular/core';
import { OnInit }        from '@angular/core';
import { DomSanitizer }  from '@angular/platform-browser';
import { SafeUrl }       from '@angular/platform-browser';

// Internal modules
import { environment }   from '@env/environment';

// Helpers
import { StorageHelper } from '@helpers/storage.helper';

// Models
import { Row }           from '@models/resolved-list.model';

@Component({
  selector    : 'app-row-link',
  templateUrl : './row-link.component.html',
  styleUrls   : ['./row-link.component.scss']
})
export class RowLinkComponent implements OnInit
{
  @Input() row !: Row;
  public image !: SafeUrl | string;

  constructor(private domSanitizer : DomSanitizer) { }

  // -------------------------------------------------------------------------------
  // ---- NOTE Init ----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async ngOnInit() : Promise<void>
  {
    this.image = await this.getImage(this.row.image);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Helpers -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  private async getImage(imgKey : string | null) : Promise<SafeUrl | string>
  {
    if (!imgKey)
      return environment.placeholder;

    const data : Blob = await StorageHelper.getIDBItem(imgKey);

    if (!data)
      return environment.placeholder;

    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
  }

}
