// Angular modules
import { Injectable } from '@angular/core';

@Injectable()
export class StringHelper
{
  public static toCamelCase(value : string) : string
  {
    return value.toLowerCase().replace(/(?:(^.)|(\s+.))/g, (match) =>
    {
      return match.charAt(match.length - 1).toUpperCase();
    });
  }

  public static toSnakeCase(value : string) : string
  {
    return value[0].toLowerCase() + value.slice(1, value.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  /** NOTE How to decode jwt token in javascript without using a library?
   * https://stackoverflow.com/a/65248576/7462178
   */
  public static getParsedJwt<T extends object = { [k: string]: string | number }>(token : string) : T | undefined
  {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch {
      return undefined
    }
  }

  public static tokenIsExpired(exp : number) : boolean
  {
    const d = new Date(0);
    d.setUTCSeconds(exp);
    return d < new Date();
  }
}
