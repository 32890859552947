// Angular modules
import { CommonModule }        from '@angular/common';
import { NgModule }            from '@angular/core';

// Internal modules
import { StaticRoutingModule } from './static-routing.module';
import { SharedModule }        from '../shared/shared.module';

// Components
import { NotFoundComponent }   from './not-found/not-found.component';

@NgModule({
  declarations:
  [
    NotFoundComponent
  ],
  imports     :
  [
    CommonModule,
    StaticRoutingModule,
    SharedModule
  ]
})
export class StaticModule {}
