// Angular modules
import { NgModule }          from '@angular/core';
import { RouterModule }      from '@angular/router';
import { Routes }            from '@angular/router';

// Components
import { NotFoundComponent } from './static/not-found/not-found.component';

// Helpers
import { AuthGuard }         from '@guards/auth.guard';
import { SyncGuard }         from '@guards/sync.guard';
import { AdminGuard }        from '@guards/admin.guard';

const routes : Routes = [
  {
    path             : 'auth',
    loadChildren     : () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path             : 'home',
    loadChildren     : () => import('./pages/home/home.module').then(m => m.HomeModule),
    canLoad          : [ AuthGuard, SyncGuard ],
    canActivateChild : [ AuthGuard ]
  },
  {
    path             : 'synchronization',
    loadChildren     : () => import('./pages/synchronization/synchronization.module').then(m => m.SynchronizationModule),
    canLoad          : [ AuthGuard ],
    canActivateChild : [ AuthGuard ]
  },
  {
    path             : 'line-list',
    loadChildren     : () => import('./pages/line-list/line-list.module').then(m => m.LineListModule),
    canLoad          : [ AuthGuard, SyncGuard ],
    canActivateChild : [ AuthGuard ]
  },
  {
    path             : 'settings',
    loadChildren     : () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    canLoad          : [ AuthGuard, SyncGuard ],
    canActivateChild : [ AuthGuard ]
  },
  {
    path             : 'search',
    loadChildren     : () => import('./pages/search/search.module').then(m => m.SearchModule),
    canLoad          : [ AuthGuard, SyncGuard ],
    canActivateChild : [ AuthGuard ]
  },
  {
    path             : 'informations',
    loadChildren     : () => import('./pages/informations/informations.module').then(m => m.InformationsModule),
    canLoad          : [ AuthGuard, SyncGuard ],
    canActivateChild : [ AuthGuard ]
  },
  {
    path            : 'administration',
    loadChildren    : () => import('./pages/administration/administration.module').then(m => m.AdministrationModule),
    canLoad         : [ AuthGuard, SyncGuard, AdminGuard ],
    canActivateChild: [ AuthGuard, AdminGuard ]
  },
  {
    path            : 'validation',
    loadChildren    : () => import('./pages/validation/validation.module').then(m => m.ValidationModule),
    canLoad         : [ AuthGuard, AdminGuard ],
    canActivateChild: [ AuthGuard, AdminGuard ]
  },
  { path : '',   redirectTo : '/home', pathMatch : 'full' },
  { path : '**', component : NotFoundComponent }
];

@NgModule({
  imports   : [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports   : [ RouterModule ],
  providers : [ AuthGuard, SyncGuard, AdminGuard ]
})
export class AppRoutingModule { }
