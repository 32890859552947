// Angular modules
import { DatePipe }                from '@angular/common';
import { HttpClient }              from '@angular/common/http';
import { HttpClientModule }        from '@angular/common/http';
import { APP_INITIALIZER }         from '@angular/core';
import { Injector }                from '@angular/core';
import { NgModule }                from '@angular/core';
import { BrowserModule }           from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule }     from '@angular/service-worker';

// External modules
import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider }     from '@abacritt/angularx-social-login';
import { TranslateLoader }         from '@ngx-translate/core';
import { TranslateService }        from '@ngx-translate/core';
import { TranslateModule }         from '@ngx-translate/core';
import { TranslateHttpLoader }     from '@ngx-translate/http-loader';
import { AngularSvgIconModule }    from 'angular-svg-icon';

// Internal modules
import { AppRoutingModule }        from './app-routing.module';
import { SharedModule }            from './shared/shared.module';
import { StaticModule }            from './static/static.module';
import { environment }             from '@env/environment';

// Factories
import { appInitFactory }          from '@factories/app-init.factory';

// Services
import { AuthService }             from '@services/auth.service';
import { StoreService }            from '@services/store.service';
import { SyncService }             from '@services/sync.service';

// Components
import { AppComponent }            from './app.component';

@NgModule({
  imports :
  [
    // Angular
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled : environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy : 'registerWhenStable:30000'
    }),

    // External modules
    TranslateModule.forRoot({
      loader:
      {
        provide    : TranslateLoader,
        useFactory : (createTranslateLoader),
        deps       : [HttpClient]
      }
    }),
    AngularSvgIconModule.forRoot(),

    // Internal modules
    SharedModule,
    StaticModule,
    AppRoutingModule,
  ],
  declarations :
  [
    AppComponent,
  ],
  providers :
  [
    // External modules
    {
      provide    : APP_INITIALIZER,
      useFactory : appInitFactory,
      deps       : [ TranslateService, Injector ],
      multi      : true
    },
    {
      provide : 'SocialAuthServiceConfig',
      useValue : {
        autoLogin : false,
        providers : [
          {
            id : GoogleLoginProvider.PROVIDER_ID,
            provider : new GoogleLoginProvider(
              environment.googleClientId,
              {
                oneTapEnabled : false,
                // scopes : [ 'offline' ]
              }
            )
          },
        ],
        onError : (err) => {
          console.error('AppModule : providers -> SocialAuthServiceConfig', err);
        }
      } as SocialAuthServiceConfig,
    },

    // Services
    AuthService,
    SyncService,
    StoreService,

    // Pipes
    DatePipe
  ],
  bootstrap:
  [
    AppComponent
  ]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}
