<div class="not-found-container">
  <div class="not-found text-center">
    <h1 class="mb-3">{{ 'NOT_FOUND' | translate }}</h1>
    <!-- NOTE Authenticated user -->
    <button class="btn btn-primary" *ngIf="storeService.isAuthenticated$ | async"
      color="primary"
      [routerLink]="['/home']">
        {{ 'GO_TO_HOMEPAGE' | translate }}
    </button>
    <!-- NOTE Unknown user -->
    <button class="btn btn-primary" *ngIf="!(storeService.isAuthenticated$ | async)"
      [routerLink]="['/auth']">
        {{ 'GO_TO_LOGIN_PAGE' | translate }}
    </button>
  </div>
</div>
