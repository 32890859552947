// Angular modules
import { Injectable }             from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CanActivateChild }       from '@angular/router';
import { CanLoad }                from '@angular/router';
import { Route }                  from '@angular/router';
import { RouterStateSnapshot }    from '@angular/router';
import { UrlSegment }             from '@angular/router';

// Helpers
import { StorageHelper }          from '@helpers/storage.helper';

// Services
import { AuthService }            from '@services/auth.service';
import { StoreService }           from '@services/store.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivateChild
{

  constructor
  (
    private storeService : StoreService,
    private authService  : AuthService,
  )
  {
  }

  public async canActivateChild(route : ActivatedRouteSnapshot, state : RouterStateSnapshot) : Promise<boolean>
  {
    return this.checkCanNavigate();
  }

  public async canLoad(route : Route, segments : UrlSegment[]) : Promise<boolean>
  {
    return this.checkCanNavigate();
  }

  private async checkCanNavigate() : Promise<boolean>
  {
    const connectivity    = this.storeService.getConnectivity();
    const activeVersion   = await StorageHelper.getActiveVersion();
    const user            = StorageHelper.getUser();
    const isAuthenticated = await this.authService.isAuthenticated();

    if (!connectivity && activeVersion && user)
      return true;

    if (isAuthenticated)
      return true;

    // console.log('checkCanNavigate : connectivity', connectivity)
    // console.log('checkCanNavigate : activeVersion', activeVersion)
    // console.log('checkCanNavigate : user', user)
    // console.log('checkCanNavigate : isAuthenticated', isAuthenticated)

    await this.authService.signOut(true);
    return false;
  }
}
