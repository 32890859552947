<div class="modal-header {{ modalData.headerClasses }}">
  <!-- NOTE Dynamic title -->
  <h6 class="modal-title">{{ modalData.title }}</h6>
  <button type="button" class="btn-close" *ngIf="modalData.closable" aria-label="Close" (click)="activeModal.dismiss()">
  </button>
</div>
<div class="modal-body auto">

  <!-- NOTE Component -->
  <ng-template modal-wrapper-host></ng-template>

</div>
