// Angular modules
import { Component }     from '@angular/core';
import { OnInit }        from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Router }        from '@angular/router';

// External modules
import { NgbOffcanvas }  from '@ng-bootstrap/ng-bootstrap';
import { Subscription }  from 'rxjs';

// Services
import { StoreService }  from '@services/store.service';

@Component({
  selector    : 'app-layout-header',
  templateUrl : './layout-header.component.html',
  styleUrls   : ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit
{
  public title           : string  = this.storeService.getPageTitle();
  public isLoading       : boolean = false;
  public isMenuCollapsed : boolean = true;

  // NOTE Subscription
  private titleSub         : Subscription;

  constructor
  (
    public  storeService     : StoreService,
    private router           : Router,
    private offcanvasService : NgbOffcanvas,
  )
  {
    this.titleSub         = this.titleSubscription();
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Init ----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async ngOnInit() : Promise<void>
  {
    // NOTE Close offcanvas on navigation
    this.router.events.subscribe(event =>
    {
      if (event instanceof NavigationEnd)
        this.offcanvasService.dismiss();
    });
  }

  public ngOnDestroy() : void
  {
    this.titleSub.unsubscribe();
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Actions -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async onClickOffcanvas(content : any) : Promise<void>
  {
    this.offcanvasService.open(content);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Computed props ------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Helpers -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Requests ------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Subscriptions -------------------------------------------------------
  // -------------------------------------------------------------------------------

  private titleSubscription() : Subscription
  {
    return this.storeService.pageTitle$.subscribe((title : string) =>
    {
      this.title = title;
    });
  }
}
