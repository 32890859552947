// External modules
import { ArrayTyper }        from '@caliatys/array-typer';

// Models
import { ImageDictionaries } from '@models/image-dictionaries.model';
import { Line }              from '@models/line.model';

export class Database
{
  public lines  !: Line[];
  public images !: ImageDictionaries;

  constructor(json : any)
  {
    if (!json)
      return;

    json        = JSON.parse(json);
    this.lines  = ArrayTyper.asArray(Line, json.Lines);
    this.images = json.Images;
  }
}
