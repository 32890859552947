// Angular modules
import { EventEmitter }     from '@angular/core';
import { Input }            from '@angular/core';
import { OnInit }           from '@angular/core';
import { Output }           from '@angular/core';
import { Component }        from '@angular/core';
import { FormControl }      from '@angular/forms';
import { Validators }       from '@angular/forms';
import { FormGroup }        from '@angular/forms';

// External modules
import { TranslateService } from '@ngx-translate/core';

// Helpers
import { StorageHelper }    from '@helpers/storage.helper';

// Models
import { Lang }             from '@models/lang.model';

// Services
import { StoreService }     from '@services/store.service';

@Component({
  selector    : 'app-form-settings',
  templateUrl : './form-settings.component.html',
  styleUrls   : ['./form-settings.component.scss']
})
export class FormSettingsComponent implements OnInit
{
  @Input()  data       !: { pageTitle : string };
  @Output() submitData  : EventEmitter<any>  = new EventEmitter();
  @Output() submitClose : EventEmitter<null> = new EventEmitter();

  // NOTE Form
  public formGroup !: FormGroup<{
    language : FormControl<string>,
  }>;

  public activeLanguage !: Lang;

  public languages       : Lang[]   = [];

  public dataIsLoading : boolean = true;
  public formIsLoading : boolean = false;

  constructor
  (
    private storeService     : StoreService,
    private translateService : TranslateService,
  )
  {
  }

  public async ngOnInit() : Promise<void>
  {
    await this.getLanguages();
    this.initFormGroup();
    this.dataIsLoading = false;
  }

  private async initFormGroup() : Promise<void>
  {
    this.formGroup = new FormGroup({
      language : new FormControl<string>({
        value    : this.activeLanguage.Code,
        disabled : false,
      }, { validators : [Validators.required], nonNullable : true }),
    });
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Actions -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async onClickSubmit() : Promise<void>
  {
    this.formIsLoading = true;

    const languageCode = this.formGroup.controls.language.value;

    const language = this.languages.find(l => l.Code === languageCode);

    if (language && this.activeLanguage !== language)
    {
      await StorageHelper.setActiveLanguage(language);
      await this.updateTranslation();
    }

    this.formIsLoading = false;
    this.submitClose.emit();
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Requests ------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // NOTE Get

  private async getLanguages() : Promise<void>
  {
    // NOTE Get languages
    this.languages = await StorageHelper.getLanguages();

    // NOTE Get active language
    let activeLanguage = await StorageHelper.getActiveLanguage();
    if (!activeLanguage || !this.languages.find(l => l.Code === activeLanguage?.Code))
    {
      activeLanguage = this.languages[0];
      await StorageHelper.setActiveLanguage(activeLanguage);
      await this.updateTranslation();
    }
    this.activeLanguage = activeLanguage;
  }

  private async updateTranslation() : Promise<void>
  {
    const active = await StorageHelper.getActiveLanguageWithJSON();
    if (!active)
    {
      console.error('FormSettingsComponent : updateTranslation -> getIDBItem');
      return;
    }

    // this.translateService.setTranslation(active.lang.Code, active.data);
    this.translateService.use(active.lang.Code);

    // NOTE Update page title
    this.storeService.setPageTitle(this.data.pageTitle);
  }

}
