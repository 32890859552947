// Angular modules
import { CommonModule }                   from '@angular/common';
import { NgModule }                       from '@angular/core';
import { FormsModule }                    from '@angular/forms';
import { ReactiveFormsModule }            from '@angular/forms';
import { RouterModule }                   from '@angular/router';

// External modules
import { NgToArrayPipeModule }            from 'angular-pipes';
import { TranslateModule }                from '@ngx-translate/core';
import { AngularSvgIconModule }           from 'angular-svg-icon';
import { NgbModule }                      from '@ng-bootstrap/ng-bootstrap';
import { NgEncodeURIComponentPipeModule } from 'angular-pipes';

// Components
import { ToastComponent }                 from '@blocks/toast/toast.component';
import { ProgressBarComponent }           from '@blocks/progress-bar/progress-bar.component';
import { BackBtnComponent }               from '@blocks/back-btn/back-btn.component';
import { BlockSlideComponent }            from '@blocks/block-slide/block-slide.component';
import { SideMenuComponent }              from '@blocks/side-menu/side-menu.component';
import { RowLinkComponent }               from '@blocks/row-link/row-link.component';

// Forms
import { FormSettingsComponent }     from '@forms/form-settings/form-settings.component';

// Modals
import { ModalWrapperComponent }     from '@modals/modal-wrapper/modal-wrapper.component';

// Layouts
import { LayoutHeaderComponent }     from '@layouts/layout-header/layout-header.component';
import { PageLayoutComponent }       from '@layouts/page-layout/page-layout.component';

// Pipes

// Directives
import { ModalWrapperDirective }     from '@directives/modal-wrapper.directive';

@NgModule({
  imports : [
    // Angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // External modules
    TranslateModule,
    AngularSvgIconModule,
    NgbModule,
    NgToArrayPipeModule,
    NgEncodeURIComponentPipeModule,
  ],
  declarations : [
    // Components
    SideMenuComponent,
    RowLinkComponent,
    BackBtnComponent,
    BlockSlideComponent,
    ToastComponent,
    ProgressBarComponent,

    // Forms
    FormSettingsComponent,

    // Modals
    ModalWrapperComponent,

    // Layouts
    LayoutHeaderComponent,
    PageLayoutComponent,

    // Pipes

    // Directives
    ModalWrapperDirective,
  ],
  exports : [
    // Angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // External modules
    TranslateModule,
    AngularSvgIconModule,
    NgbModule,
    NgEncodeURIComponentPipeModule,
    NgToArrayPipeModule,

    // Components
    SideMenuComponent,
    RowLinkComponent,
    BackBtnComponent,
    BlockSlideComponent,
    ToastComponent,
    ProgressBarComponent,

    // Forms
    FormSettingsComponent,

    // Modals
    ModalWrapperComponent,

    // Layouts
    LayoutHeaderComponent,
    PageLayoutComponent,

    // Pipes

    // Directives
    ModalWrapperDirective,
  ]
})
export class SharedModule { }
