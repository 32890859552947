export class User
{
  public email : string;
  public name  : string;
  public type  : 'google' | 'cognito';

  constructor(email : string, name : string, type : 'google' | 'cognito')
  {
    this.email = email;
    this.name  = name;
    this.type  = type;
  }
}
