// Angular modules
import { Component }     from '@angular/core';
import { OnDestroy }     from '@angular/core';
import { OnInit }        from '@angular/core';
import { Router }        from '@angular/router';

// External modules
import { Amplify }       from 'aws-amplify';
import { Subscription }  from 'rxjs';

// Internal modules
import { ToastManager }  from '@blocks/toast/toast.manager';
import { environment }   from '@env/environment';

// Helpers
import { StorageHelper } from '@helpers/storage.helper';

// Services
import { AuthService }   from '@services/auth.service';
import { StoreService }  from '@services/store.service';
import { SyncService }   from '@services/sync.service';

// TODO https://github.com/gentooboontoo/js-quantities
// TODO https://github.com/ben-ng/convert-units

@Component({
  selector    : 'app-root',
  templateUrl : './app.component.html',
  styleUrls   : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
  // NOTE Subscriptions
  private authSub                        !: Subscription;
  private connectivitySub                !: Subscription;
  private refreshUpdateAvailableInterval !: NodeJS.Timeout | undefined;

  constructor
  (
    private router           : Router,
    private syncService      : SyncService,
    private authService      : AuthService,
    private storeService     : StoreService,
    private toastManager     : ToastManager,
  )
  {
    this.connectivitySubscription();
    this.authSubscription();
  }

  public async ngOnInit() : Promise<void>
  {
    this.initAmplify();
    await this.initIsAuthenticated();

    // NOTE Init storage
    StorageHelper.init().catch(err =>
    {
      console.error('AppComponent', 'ngOnInit -> StorageHelper.init', err.toString());
      this.toastManager.quickShow(err);
    });
  }

  public ngOnDestroy() : void
  {
    this.authSub.unsubscribe();
    this.connectivitySub.unsubscribe();
    if (this.refreshUpdateAvailableInterval)
      clearInterval(this.refreshUpdateAvailableInterval);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Init ----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  private initAmplify() : void
  {
    Amplify.configure({
      Auth : {
        identityPoolId     : environment.identityPoolId,
        region             : environment.region,
        userPoolId         : environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
      },
    });
  }

  private async initIsAuthenticated() : Promise<void>
  {
    const isAuthenticated = await this.authService.isAuthenticated();
    this.storeService.setIsAuthenticated(isAuthenticated);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Actions -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Computed props ------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Helpers -------------------------------------------------------------
  // -------------------------------------------------------------------------------

  private async checkUpdateAvailable() : Promise<void>
  {
    // NOTE Is online and authenticated
    if (this.storeService.getConnectivity() && this.storeService.getIsAuthenticated())
    {
      // NOTE Interval already running
      if (this.refreshUpdateAvailableInterval)
        return;

      // NOTE Refresh update available each 5 seconds
      this.refreshUpdateAvailableInterval = setInterval(_ =>
      {
        this.refreshUpdateAvailable()
      }, environment.checkUpdateInterval);
      return;
    }

    // NOTE Clear interval
    if (this.refreshUpdateAvailableInterval)
    {
      clearInterval(this.refreshUpdateAvailableInterval);
      this.refreshUpdateAvailableInterval = undefined;
    }
  }

  private async refreshUpdateAvailable() : Promise<void>
  {
    // NOTE Is not online
    if (!this.storeService.getConnectivity() || !this.storeService.getIsAuthenticated())
      return;

    // NOTE Is not authenticated
    const isAuthenticated = await this.authService.isAuthenticated();
    if (!isAuthenticated)
      return;

    // NOTE Check versions
    const serverVersion = await this.syncService.getLastVersion();
    const localVersion  = await StorageHelper.getActiveVersion();
    if (!serverVersion || !localVersion)
      return;

    // NOTE Update store
    const updateAvailable = serverVersion !== localVersion;
    this.storeService.setUpdateAvailable(updateAvailable);

    // NOTE Redirect
    if (updateAvailable)
      this.router.navigate(['/synchronization']);
  }

  // -------------------------------------------------------------------------------
  // ---- NOTE Requests ------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // ---- NOTE Subscriptions -------------------------------------------------------
  // -------------------------------------------------------------------------------

  private authSubscription() : void
  {
    this.authSub = this.storeService.isAuthenticated$.subscribe(async isAuthenticated =>
    {
      this.checkUpdateAvailable();
    });
  }

  private connectivitySubscription() : void
  {
    this.connectivitySub = this.storeService.connectivity$.subscribe(async isOnline =>
    {
      this.checkUpdateAvailable();
    });
  }
}
