// Angular modules
import { Injectable }             from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CanActivateChild }       from '@angular/router';
import { Router }                 from '@angular/router';
import { CanLoad }                from '@angular/router';
import { Route }                  from '@angular/router';
import { RouterStateSnapshot }    from '@angular/router';
import { UrlSegment }             from '@angular/router';

// Helpers
import { StorageHelper }          from '@helpers/storage.helper';

// Enums
import { UserRight }              from '@enums/user-right.enum';

@Injectable()
export class AdminGuard implements CanLoad, CanActivateChild
{

  constructor
  (
    private router : Router,
  )
  {
  }

  public async canActivateChild(route : ActivatedRouteSnapshot, state : RouterStateSnapshot) : Promise<boolean>
  {
    return this.checkUserRole();
  }

  public async canLoad(route : Route, segments : UrlSegment[]) : Promise<boolean>
  {
    return this.checkUserRole();
  }

  private async checkUserRole() : Promise<boolean>
  {
    const userRight = StorageHelper.getUserRight();

    if (userRight && userRight === UserRight.Admin)
      return true;

    this.router.navigate(['/home']);
    return false;
  }
}
