// Enums
import { EnvEnum } from '@enums/env.enum';

// Packages
import packageJson from '../../package.json';

export const environment = {
  appName                  : 'AltecRef',
  production               : false,
  version                  : packageJson.version,
  env                      : EnvEnum.DEV,
  author                   : 'Caliatys',
  placeholder              : './assets/img/image-placeholder.png',
  arnLambda                : 'arn:aws:lambda:eu-west-1:793484060842:function:',
  validateUserEmailEndpoint: 'https://altec-references-dev.apps.airliquide.com/api/validateUserEmail',
  syncLambda               : 'altc00-dev-euw1-lambda-Synchronize',
  sendMailLambda           : 'altc00-dev-euw1-lambda-SendMail',
  requestUserCreationEndpoint: 'https://altec-references-dev.apps.airliquide.com/api/requestUserCreation',
  manageUserCreationLambda   : 'altc00-dev-euw1-lambda-ManageUserCreationRequest',
  slideLambda                : 'altc00-dev-euw1-lambda-GenerateSlide',
  checkUpdateInterval        : 60000,
  XHR_TIMEOUT                : 60000,
  S3_BUCKET                  : 'altc00-dev-euw1-s3-db',
  identityPoolId             : 'eu-west-1:82acba40-f522-48c8-a7ad-f52a3f35cc66',
  region                     : 'eu-west-1',
  userPoolId                 : 'eu-west-1_nq2V0eyQI',
  userPoolWebClientId        : '28eks0fkljcqk9dm294odui6a6',
  googleClientId             : '7072712692-4mlgj97c3a4ssgjt1tqu7411ch53n3g7.apps.googleusercontent.com',
};
