export enum StorageKey
{
  // NOTE User
  USER           = 'User',
  USER_RIGHT     = 'UserRight',
  // NOTE Version
  ACTIVE_VERSION = 'ActiveVersion',
  // NOTE Database
  DATABASE       = 'Database',
  // NOTE Preferences
  ACTIVE_LANG    = 'ActiveLang',
}
