<div class="text-center p-2 mb-4">
  <img src="./assets/img/air-liquide-creative-oxygen.svg" alt="Logo Air Liquide" id="air-liquide-logo" class="img-fluid" />
</div>

<ul class="list-unstyled">
  <li class="px-2 py-3">
    <a [routerLink]="['/home']" title="{{ 'HOME' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-home.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'HOME' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/line-list/market']" title="{{ 'BY_MARKET' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'BY_MARKET' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/line-list/cluster']" title="{{ 'BY_CLUSTER' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'BY_CLUSTER' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/line-list/domain']" title="{{ 'BY_DOMAIN' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'BY_DOMAIN' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/line-list/gases']" title="{{ 'BY_GASES' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'BY_GASES' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/line-list/supplyChain']" title="{{ 'BY_SUPPLY_CHAIN' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'BY_SUPPLY_CHAIN' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3 border-bottom">
    <a [routerLink]="['/search']" title="{{ 'SEARCH' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-search.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'SEARCH' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/synchronization']" title="{{ 'SYNCHRONIZATION' | translate }}" class="text-decoration-none">
      <svg-icon *ngIf="storeService.updateAvailable$ | async" class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization-pending.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <svg-icon *ngIf="!(storeService.updateAvailable$ | async)" class="align-middle me-3" [src]="'assets/img/icons/mat-synchronization.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'SYNCHRONIZATION' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/settings']" title="{{ 'SETTINGS' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-tune.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'SETTINGS' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3">
    <a [routerLink]="['/informations']" title="{{ 'INFORMATIONS' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-info.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'INFORMATIONS' | translate }}</span>
    </a>
  </li>
  <li class="px-2 py-3 border-bottom" *ngIf="isAdmin">
    <a [routerLink]="['/administration']" *ngIf="storeService.connectivity$ | async" title="{{ 'ADMINISTRATION' | translate }}" class="text-decoration-none">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-business.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'ADMINISTRATION' | translate }}</span>
    </a>
    <div *ngIf="!(storeService.connectivity$ | async)" class="disabled-item">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-business.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'ADMINISTRATION' | translate }}</span>
    </div>
  </li>
  <li class="px-2 py-3">
    <div (click)="onClickSignOut()" class="fake-link d-inline-block cursor-pointer" title="{{ 'LOG_OUT' | translate }}">
      <svg-icon class="align-middle me-3" [src]="'assets/img/icons/mat-power.svg'" [svgStyle]="{ 'fill': 'currentColor', 'height': '24px', 'width' : '24px' }"></svg-icon>
      <span class="align-middle">{{ 'LOG_OUT' | translate }}</span>
    </div>
  </li>
</ul>
