// Angular modules
import { Injectable }   from '@angular/core';

// External modules
import { ArrayTyper }   from '@caliatys/array-typer';
import * as localForage from 'localforage';

// Internal modules
import { environment }  from '@env/environment';

// Enums
import { StorageKey }   from '@enums/storage-key.enum';
import { UserRight }    from '@enums/user-right.enum';

// Models
import { Lang }         from '@models/lang.model';
import { User }         from '@models/user.model';
import { Database }     from '@models/database.model';

@Injectable()
export class StorageHelper
{
  private static storagePrefix: string = environment.appName + '_' + environment.env + '_';

  // ----------------------------------------------------------------------------------------------
  // SECTION Methods ------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------

  // NOTE User

  public static setUser(user : User) : void
  {
    this.setItem(StorageKey.USER, user);
  }

  public static getUser() : User | null
  {
    return this.getItem(StorageKey.USER);
  }

  public static removeUser() : void
  {
    this.removeItem(StorageKey.USER);
  }

  // NOTE User right

  public static setUserRight(userRight : UserRight) : void
  {
    this.setItem(StorageKey.USER_RIGHT, userRight);
  }

  public static getUserRight() : UserRight | null
  {
    return this.getItem(StorageKey.USER_RIGHT);
  }

  public static removeUserRight() : void
  {
    this.removeItem(StorageKey.USER_RIGHT);
  }

  // NOTE Languages

  public static async getLanguages() : Promise<Lang[]>
  {
    // const data = await this.getIDBItem(S3Key.LANG);
    const data = [
      { Code : 'en', Name : 'English'  },
      { Code : 'fr', Name : 'Francais' },
    ];
    return data ? ArrayTyper.asArray(Lang, data) : [];
  }

  public static async setActiveLanguage(lang : Lang) : Promise<void>
  {
    this.setIDBItem(StorageKey.ACTIVE_LANG, lang);
  }

  public static async getActiveLanguage() : Promise<Lang | null>
  {
    return this.getIDBItem(StorageKey.ACTIVE_LANG);
  }

  public static async getActiveLanguageWithJSON() : Promise<{ lang : Lang, data : any } | null>
  {
    const activeLanguage = await StorageHelper.getActiveLanguage();
    if (!activeLanguage)
      return null;
    // const jsonStorageKey = activeLanguage.Code + '.json';
    // const json = await StorageHelper.getIDBItem(jsonStorageKey);
    const json = null;
    return { lang : activeLanguage, data : json };
  }

  // NOTE Active version

  public static async setActiveVersion(version : string) : Promise<void>
  {
    this.setIDBItem(StorageKey.ACTIVE_VERSION, version);
  }

  public static async getActiveVersion() : Promise<string | null>
  {
    return this.getIDBItem(StorageKey.ACTIVE_VERSION);
  }

  // NOTE Database

  public static setDatabase(database : Database) : void
  {
    this.setItem(StorageKey.DATABASE, database);
  }

  public static getDatabase() : Database | null
  {
    return this.getItem(StorageKey.DATABASE);
  }

  // !SECTION Methods

  // ----------------------------------------------------------------------------------------------
  // SECTION Storage ------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------

  // NOTE LocalStorage

  public static setItem(key : string, value : any, prefix : boolean = true) : void
  {
    const itemKey = this.prefixer(key, prefix);
    localStorage.setItem(itemKey, JSON.stringify(value));
  }

  public static getItem(key : string, prefix : boolean = true) : any
  {
    const itemKey = this.prefixer(key, prefix);
    const res = localStorage.getItem(itemKey);
    if (res !== 'undefined')
      return JSON.parse(res as any);
    console.error('StorageHelper : getItem -> undefined key');
    return null;
  }

  public static removeItem(key : string, prefix : boolean = true) : void
  {
    const itemKey = this.prefixer(key, prefix);
    localStorage.removeItem(itemKey);
  }

  public static async clearLocalStorage(): Promise<void>
  {
    localStorage.clear();
  }

  // NOTE LocalForage

  public static async getIDBKeys() : Promise<string[]>
  {
    return await localForage.keys();
  }

  public static async setIDBItem(key : string, value : any, prefix : boolean = true) : Promise<void>
  {
    const itemKey = this.prefixer(key, prefix);
    return await localForage.setItem(itemKey, value);
  }

  public static async getIDBItem(key : string, prefix : boolean = true) : Promise<any | null>
  {
    const itemKey = this.prefixer(key, prefix);
    return await localForage.getItem(itemKey);
  }

  public static async removeIDBItem(key : string, prefix : boolean = true) : Promise<void>
  {
    const itemKey = this.prefixer(key, prefix);
    return localForage.removeItem(itemKey);
  }

  public static getKeys(all : boolean = false) : string[]
  {
    const keys : string[] = [];
    // NOTE Keys
    for (const key in localStorage)
      keys.push(key);
    if (all)
      return keys;
    // NOTE Prefixed keys
    return keys.filter((item) => item.startsWith(this.storagePrefix));
  }

  public static async clearLocalForage(): Promise<void>
  {
    await localForage.clear();
  }

  // NOTE Init

  public static init() : Promise<any>
  {
    return new Promise<void>((resolve, reject) => {
      try { // Firefox & Safari, in Private Browsing Mode throw QuotaExceededError
        console.log('StorageHelper : init -> try');

        let storage    = window['localStorage'];
        let storageKey = 'storage-test';

        storage.setItem(storageKey, storageKey);
        let storedValue = storage.getItem(storageKey);
        if (storedValue !== storageKey)
          throw new Error();
        storage.removeItem(storageKey);

        this.setIDBItem(storageKey, storageKey).then(_ => {
          this.getIDBItem(storageKey).then(storedIDBValue => {
            if (storedIDBValue !== storageKey)
              throw new Error();
            this.removeIDBItem(storageKey).then(_ => {
              return resolve();
            }).catch(_ => {
              throw new Error();
            });
          }).catch(_ => {
            throw new Error();
          });
        }).catch(_ => {
          throw new Error();
        });
      } catch (e) {
        let error = 'Your web browser does not support storing settings locally. The most common cause of this is using "Private Browsing Mode".';
        console.error('StorageHelper : init -> catch', error);
        return reject(error);
      }
    });
  }

  // !SECTION Storage

  // NOTE Private

  private static prefixer(key : string, autoPrefix : boolean) : string
  {
    let itemKey = key;
    if (autoPrefix)
      itemKey = this.storagePrefix + key;
    return itemKey;
  }

}
